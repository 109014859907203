import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
  SHOW_MESSAGE,
} from "../constants/ActionTypes";
import axios from "util/Api";
import { NotificationManager } from "react-notifications";

// Aws Cognito
import { CognitoUser, AuthenticationDetails } from "amazon-cognito-identity-js";
import UserPool from "../UserPool";

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};

export const userSignIn = ({ email, password, props, setShowResetPwBtn }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        setUpLoggedUser(user, result, dispatch);

        props.history.push({
          pathname: "/app/main",
        });
      },

      onFailure: (error) => {
        dispatch({
          type: SHOW_MESSAGE,
          payload: error.message,
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });

        setShowResetPwBtn(true);
      },

      // More info:
      // https://stackoverflow.com/questions/51103676/aws-cognito-react-js-newpasswordrequired-challenge
      newPasswordRequired: (data) => {
        dispatch({ type: FETCH_SUCCESS });

        delete data.email_verified;

        window.userCognito = user;

        props.history.push({
          pathname: "/signin-setup-new-password",
          state: { email, data },
        });
      },
    });
  };
};

export const userResetPassword = ({
  verificationCode,
  newPassword,
  email,
  props,
}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: (result) => {
        NotificationManager.success(
          "New password changed with success!",
          null,
          3000
        );
        setUpLoggedUser(user, result, dispatch);

        dispatch({ type: FETCH_SUCCESS });

        setTimeout(() => {
          props.history.push({
            pathname: "/",
          });
        }, 3200);
      },

      onFailure: (error) => {
        dispatch({
          type: SHOW_MESSAGE,
          payload: error.message,
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      },
    });
  };
};

export const userForgotPassword = ({ email }) => {
  return (dispatch) => {
    const user = new CognitoUser({
      Username: email,
      Pool: UserPool,
    });

    user.forgotPassword({
      onSuccess: () => {
        NotificationManager.success(
          "A verification code was sent to your email! Please use it in the field below to be able to reset your password",
          null,
          5000
        );
      },
      onFailure: (error) => {
        NotificationManager.error(error.message, null, 5000);
      },
    });
  };
};

export const userSignInSetNewPassword = ({
  nickname,
  password,
  data,
  props,
}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    const user = window.userCognito;

    data.nickname = nickname;

    user.completeNewPasswordChallenge(password, data, {
      onFailure: (error) => {
        dispatch({
          type: SHOW_MESSAGE,
          payload: error.message,
        });
        dispatch({ type: FETCH_ERROR, payload: error.message });
      },
      onSuccess: (result) => {
        setUpLoggedUser(user, result, dispatch);

        props.history.push({
          pathname: "/app/main",
        });
      },
    });
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
  };
};

export const userSignOut = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });

    const user = UserPool.getCurrentUser();
    if (user !== null) {
      user.signOut();
    }

    localStorage.removeItem("token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("nickname");
    localStorage.removeItem("user");

    dispatch({ type: FETCH_SUCCESS });
    dispatch({ type: SIGNOUT_USER_SUCCESS });
  };
};

const setUpLoggedUser = (user, result, dispatch) => {
  if (result === undefined) {
    return; // When the user reset his password
  }

  localStorage.setItem("token", result.idToken.jwtToken);
  localStorage.setItem("refresh_token", result.refreshToken.token);

  user.getSession((err, session) => {
    getUserNickname(user).then((nickname) => {
      // TODO: Understand how USER_DATA dispatch works
      dispatch({ type: USER_DATA, payload: { nickname } });
      localStorage.setItem("nickname", nickname);
      // Set USERNAME for the API calls
      localStorage.setItem("user", user.username);
    });
  });

  axios.defaults.headers.common["Authorization"] =
    "Bearer " + result.idToken.jwtToken;

  dispatch({ type: FETCH_SUCCESS });
  dispatch({
    type: USER_TOKEN_SET,
    payload: result.accessToken.jwtToken,
  });
};

const getUserNickname = (user) => {
  return new Promise((resolve, reject) => {
    user.getUserAttributes((err, result) => {
      if (err) {
        reject(err);
      }

      for (let i = 0; i < result.length; i++) {
        if (result[i].getName() === "nickname") {
          resolve(result[i].getValue());
        }
      }
    });
  });
};
