import React from "react";
import CustomScrollbars from "util/CustomScrollbars";
import Navigation from "../../components/Navigation";

const SideBarContent = () => {
  const navigationMenus = [
    {
      name: "sidebar.main",
      type: "section",
      children: [
        {
          name: "sidebar.channels",
          icon: "view-dashboard",
          type: "collapse",
          children: [
            {
              name: "sidebar.channels.new",
              type: "item",
              link: "/app/channels/create",
            },
            {
              name: "sidebar.channels.list",
              type: "item",
              link: "/app/channels",
            },
          ],
        },
        {
          name: "sidebar.topics",
          icon: "view-dashboard",
          type: "collapse",
          children: [
            {
              name: "sidebar.topics.new",
              type: "item",
              link: "/app/topics/create",
            },
            {
              name: "sidebar.topics.new.youtube",
              type: "item",
              link: "/app/topics/create/youtube",
            },
            {
              name: "sidebar.topics.list",
              type: "item",
              link: "/app/topics",
            },
          ],
        },
      ],
    },
  ];

  return (
    <CustomScrollbars className=" scrollbar">
      <Navigation menuItems={navigationMenus} />
    </CustomScrollbars>
  );
};

export default SideBarContent;
