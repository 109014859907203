import React from "react";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";

const Footer = () => {
  return (
    <footer className="app-footer">
      <span className="d-inline-block">Reserved Manosphere</span>
      <Button
        href="https://guilherme-ferreira.me/get-in-touch/"
        target="_blank"
        size="small"
        color="primary"
      >
        <IntlMessages id="footer.help.message" />
      </Button>
    </footer>
  );
};
export default Footer;
