import axios from "axios";

// Aws Cognito
import UserPool from "../UserPool";

const axiosInstance = axios.create({
  baseURL: "https://api.reserved-manosphere.guilherme-ferreira.me",
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (401 === error.response.status) {
      const user = UserPool.getCurrentUser();

      user.getSession((err, session) => {
        const refresh_token = session.getRefreshToken();
        user.refreshSession(refresh_token, (rErr, rSession) => {
          if (rErr) {
            throw rErr;
          } else {
            localStorage.setItem("token", rSession.idToken.jwtToken);
            localStorage.setItem("refresh_token", rSession.refreshToken.token);

            setTimeout(() => {
              alert(
                "Your credentials expired! It will be automatically renewed, but if something does not work, please log out and come back again :)"
              );
            }, 4500);

            setTimeout(() => {
              window.location.reload();
            }, 5000);
          }
        });
      });

      // TODO: Do the refresh token
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosInstance;
