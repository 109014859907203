import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import asyncComponent from "../../util/asyncComponent";

const Routes = ({ match }) => (
  <Switch>
    <Route
      path={`${match.url}/main`}
      component={asyncComponent(() => import("../../components/MainPage"))}
    />
    <Route
      path={`${match.url}/channels/create`}
      component={asyncComponent(() => import("../../components/Channels/Form"))}
    />
    <Route
      path={`${match.url}/channels/:id`}
      component={asyncComponent(() => import("../../components/Channels/Form"))}
    />
    <Route
      path={`${match.url}/channels`}
      component={asyncComponent(() =>
        import("../../components/Channels/ChannelsDataTable")
      )}
    />
    <Route
      exact
      path={`${match.url}/topics/create`}
      component={asyncComponent(() => import("../../components/Topics/Form"))}
    />
    <Route
      path={`${match.url}/topics/create/youtube`}
      component={asyncComponent(() =>
        import("../../components/Topics/FormYoutube")
      )}
    />
    <Route
      path={`${match.url}/topics/:id`}
      component={asyncComponent(() => import("../../components/Topics/Form"))}
    />
    <Route
      path={`${match.url}/topics`}
      component={asyncComponent(() =>
        import("../../components/Topics/TopicsDataTable")
      )}
    />
    {/*<Route component={asyncComponent(() => import("app/routes/extraPages/routes/404"))}/>*/}
  </Switch>
);

export default withRouter(Routes);
