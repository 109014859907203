import React from "react";
import { Link, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";
import { toggleCollapsedNav } from "actions/Setting";

const Index = (props) => {
  const dispatch = useDispatch();
  const { drawerType, navCollapsed } = useSelector(({ settings }) => settings);

  const onToggleCollapsedNav = (e) => {
    const val = !navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const drawerStyle = drawerType.includes(FIXED_DRAWER)
    ? "d-block d-xl-none"
    : drawerType.includes(COLLAPSED_DRAWER)
    ? "d-block"
    : "d-none";

  return (
    <AppBar className="app-main-header">
      <Toolbar className="app-toolbar" disableGutters={false}>
        <IconButton
          className={`jr-menu-icon mr-3 ${drawerStyle}`}
          aria-label="Menu"
          onClick={onToggleCollapsedNav}
        >
          <span className="menu-icon" />
        </IconButton>
        <Link className="app-logo mr-2 d-none d-sm-block" to="/app/main">
          <img
            src={require("assets/images/manosphere/manosphere_logo_small.png")}
            alt="Reserved Manosphere"
            title="Reserved Manosphere"
          />
        </Link>
        <div className="ellipse-shape" />
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(Index);
