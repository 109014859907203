import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import { userSignInSetNewPassword } from "actions/Auth";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

const SignInSetupNewPassword = (props) => {
  const [nickname, setNickname] = useState();
  const [password, setPassword] = useState();
  const dispatch = useDispatch();
  const token = useSelector(({ auth }) => auth.token);
  const { loading, message } = useSelector(({ common }) => common);
  const email =
    props.history.location.state !== undefined
      ? props.history.location.state.email
      : "";

  const data =
    props.history.location.state !== undefined
      ? props.history.location.state.data
      : "";

  useEffect(() => {
    if (token !== null) {
      props.history.push("/");
    }

    if (email === "" || data === "") {
      props.history.push("/signin");
    }
  }, [token, email, data, props.history]);

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(
      userSignInSetNewPassword({
        nickname,
        password,
        data,
        props,
      })
    );
  };

  return (
    <div className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
      <div className="app-login-main-content">
        <div className="app-logo-content d-flex align-items-center justify-content-center">
          <Link className="logo-lg" to="/" title="Reserved Manosphere">
            <img
              src={require("assets/images/manosphere/manosphere_logo_small.png")}
              alt="Reserved Manosphere"
              title="Reserved Manosphere"
            />
          </Link>
        </div>

        <div className="app-login-content">
          <div className="app-login-header mb-4">
            <h1>
              <IntlMessages id="appModule.signInSetupNewPassword.title" />
            </h1>
          </div>

          <div className="app-login-form">
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
            >
              <fieldset>
                <TextField
                  label={
                    <IntlMessages id="appModule.signInSetupNewPassword.field.nickname" />
                  }
                  fullWidth
                  onChange={(event) => setNickname(event.target.value)}
                  defaultValue={nickname}
                  margin="normal"
                  className="mt-1 my-sm-3"
                  required={true}
                />
                <TextField
                  type="password"
                  label={
                    <IntlMessages id="appModule.signInSetupNewPassword.field.newPassword" />
                  }
                  fullWidth
                  onChange={(event) => setPassword(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  className="mt-1 my-sm-3"
                  required={true}
                />

                <div className="mb-3 d-flex align-items-center justify-content-between">
                  <Button variant="contained" color="primary" type="submit">
                    <IntlMessages id="appModule.signInSetupNewPassword" />
                  </Button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>

      {loading && (
        <div className="loader-view">
          <CircularProgress />
        </div>
      )}
      {message && NotificationManager.error(message)}
      <NotificationContainer />
    </div>
  );
};

export default SignInSetupNewPassword;
